<template>
  <div class="login-right">
    <div class="login_box">
      <div class="title">手机注册</div>
      <div class="content">
        <!-- 账密、手机 -->
        <a-form id="formLogin" class="user-layout-login ruleForm" ref="formReg" :form="form" @submit="handleSubmit">
          <a-form-item label="输入手机号">
            <a-input
              :maxLength="11"
              size="large"
              type="text"
              placeholder="请输入手机号"
              v-decorator="[
                'mobile',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: '请输入手机号码' },
                    { max: 11, message: '手机号码最长不能超过11位' },
                    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码' }
                  ],
                  validateTrigger: 'blur'
                }
              ]"
            >
            </a-input>
          </a-form-item>

          <a-form-item label="验证码">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <a-input
                :maxLength="4"
                size="large"
                style="width: 50%;"
                type="text"
                autocomplete="false"
                placeholder="验证码"
                v-decorator="[
                  'verifyCode',
                  {
                    initialValue: '',
                    rules: [
                      { required: true, message: '请输入验证码' },
                      { max: 4, message: '验证码不能超过4位' }
                    ],
                    validateTrigger: 'blur'
                  }
                ]"
              >
              </a-input>
              <img
                :src="'data:image/jpg;base64,' + code.base64Image"
                alt=""
                class="codeImg"
                style="width: 1.3rem;height: 0.5rem;cursor: pointer;border-radius: 5px;"
                @click="getCode()"
              />
            </div>
          </a-form-item>

          <a-form-item label="短信验证码">
            <a-input
              :maxLength="6"
              size="large"
              type="text"
              placeholder="请输入验证码"
              v-decorator="[
                'captcha',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: '请输入验证码' },
                    { max: 6, message: '验证码不能超过6位' }
                  ],
                  validateTrigger: 'blur'
                }
              ]"
            >
              <template #suffix>
                <a-button type="link" :disabled="!showButton" @click="handleFetchCode">
                  {{ showButton ? '获取验证码' : `(${countDown}) 秒后重试` }}
                </a-button>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item label="输入密码">
            <a-input-password
              :maxLength="16"
              size="large"
              type="password"
              autocomplete="false"
              placeholder="请输入密码"
              v-decorator="[
                'password',
                {
                  initialValue: '',
                  rules: [
                    { required: true, message: '请输入密码' },
                    { min: 6, max: 16, message: '请输入6-16位的密码' }
                  ],
                  validateTrigger: 'blur'
                }
              ]"
            >
            </a-input-password>
          </a-form-item>

          <a-form-item label="再次确认">
            <a-input-password
              :maxLength="16"
              size="large"
              type="password"
              autocomplete="false"
              placeholder="再次确认密码"
              v-decorator="[
                'confirmPassword',
                { initialValue: '', rules: [{ required: true, message: '请再次确认密码' }], validateTrigger: 'blur' }
              ]"
            >
            </a-input-password>
          </a-form-item>

          <a-form-item style="margin-top:0.15rem;text-align:center;">
            <div style="display: flex;justify-content: center;">
              <a-checkbox
                v-decorator="['agreeServiceTerms', { valuePropName: 'checked' }]"
                style="margin-right: 10px;"
              ></a-checkbox>
              <router-link to="/htmls/rule.html" target="_blank">
                <div>
                  <span style="color:black;">同意</span>
                  《法律条款及隐私政策》
                </div>
              </router-link>
            </div>
          </a-form-item>

          <a-form-item style="margin-top:0.15rem;text-align:center;">
            <div class="reg_bottom">
              <div class="clause" @click="goBack"><i class="el-icon-back"></i> <span>返回</span></div>
              <a-button size="large" type="primary" htmlType="submit" class="reg-button" :disabled="state.regBtn"
                >注册</a-button
              >
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 底部注册、下载 -->
    <div class="login-right-bottom">
      <div class="bottom2">
        <div>
          <!-- 四川省移民后期扶持 -->
        </div>
        <div>
          <!-- 蜀ICP备2022028199号-1 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _phoneValidate, _passwordValidate } from '@/utils/validate'
import {
  _createImageVerify,
  _verifyPhoneVerify,
  _verifyLogin,
  _verifyImageVerify
} from '@/api/modular/system/loginManage'
import { debounce } from '@/utils/index'

export default {
  data() {
    return {
      agree: false,
      registerVisible: false, //注册
      showButton: true,
      countDown: 60,
      state: {
        time: 60,
        regBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      form: this.$form.createForm(this),
      codeReg: this.$form.createForm(this),
      accountRegErrMsg: '',
      regParams: [], // 注册参数
      // 验证码
      code: {}
    }
  },
  created() {
    this.getCode()
  },
  methods: {
    goBack() {
      this.form.resetFields()
      this.$emit('changeActiveIndex', 'login')
    },
    // 获取图形验证码
    getCode() {
      const params = {
        createCodeParam: {
          characterCount: 4,
          type: 1
        },
        fontSize: 16,
        verifyCodeType: 1
      }
      _createImageVerify(params).then(res => {
        // 获取成功
        if (res.code == 200) {
          this.code = res.data
        } else {
          this.$message.error('获取验证码失败')
        }
      })
    },

    // 点击按钮时触发的事件处理器
    handleFetchCode: debounce(function() {
      const {
        form: { validateFields }
      } = this
      const validateFieldsKey = ['verifyCode', 'mobile']
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          // TODO: 调用后端接口请求发送验证码
          const params = {
            createCodeParam: {
              characterCount: 6,
              type: 1
            },
            verifyCodeKey: this.code.key,
            verifyCode: values.verifyCode,
            smsType: 1,
            phone: values.mobile,
            verifyCodeType: 1
          }

          _verifyPhoneVerify(params).then(res => {
            this.showButton = false

            // 假设接口返回成功，开始倒计时
            if (res.code == 200) {
              this.code = { ...this.code, ...res.data }
              // 接口请求完成后重置 loading 状态
              this.countDown = 60
              this.timer = setInterval(() => {
                if (this.countDown === 1) {
                  clearInterval(this.timer)
                  this.showButton = true
                }
                this.countDown -= 1
              }, 1000)

              setTimeout(() => {
                this.loading = false
              }, 1000)
            } else {
              this.$message.error(res.message)
              this.showButton = true
              clearInterval(this.timer)
            }
          })
        } else {
          this.$message.error('请先输入验证码！')
        }
      })
    }, 300),

    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state
      } = this

      // state.regBtn = true;
      if (!this.form.getFieldValue('agreeServiceTerms')) {
        this.$message.error('请先勾选同意《服务条款》，再提交！')
        return
      }
      const validateFieldsKey = ['mobile', 'password', 'captcha', 'confirmPassword', 'agreeServiceTerms']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        this.regParams = { ...this.code, ...values }

        if (this.regParams.password == this.regParams.confirmPassword) {
          if (!err) {
            const params = {
              verifyCodeKey: this.regParams.key,
              verifyCode: this.regParams.captcha,
              phone: this.regParams.mobile,
              verifyCodeType: 3,
              password: this.regParams.password
            }

            _verifyLogin(params).then(res => {
              if (res.code == 200) {
                this.$message.success('注册成功')
                this.registerVisible = false
                state.regBtn = true
                this.form.resetFields()
              } else {
                this.$message.error(res.message)
                state.regBtn = false
                return false
              }
            })
          }
        } else {
          this.$message.error('两次输入密码不一致')
          state.regBtn = false
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
button.reg-button {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 50%;
  border: none;
  border-radius: 14px;
  opacity: 1;
  background: #3f8cff;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.2637);
}

.login-right {
  flex: 0 1 47%;
  background: #fff;
  padding: 0.05rem 0.6875rem 0;
  border-radius: 0 24px 24px 0;
  // overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .login_box {
    width: 90%;
  }
  .title {
    width: 100%;
    margin-bottom: 0.1rem;
    font-family: PingFang SC;
    font-size: 0.2625rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0px;
    color: #0a1629;
  }

  .login-right-bottom {
    width: 100%;
    height: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    padding: 0.05rem 0.6875rem 0;
    .bottom1 {
      display: flex;
      justify-content: space-between;
      font-size: 0.1875rem;
      color: #1890ff;
      cursor: pointer;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
    }

    .bottom2 {
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      margin-top: 0.1rem;
    }
  }
}

.forge-password {
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #515151;
  cursor: pointer;
  font-size: 0.1875rem;
  text-decoration: underline;
  float: right;
}

.el-input__inner {
  background-color: transparent !important;
}

/deep/ .ant-input-affix-wrapper .ant-input {
  border-radius: 11px;
}

/deep/ .ant-btn-primary {
  border-radius: 23px;
}

/deep/ .ant-form-item {
  margin-bottom: 0.025rem;
}

.reg_bottom {
  display: flex;
  justify-content: center;
  .clause {
    display: flex;
    justify-content: center;
    color: #3f8cff;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
  }
}

.qr-disabled {
  filter: grayscale(100%) im !important;
}
</style>

<template>
  <div class="login-wrap">
    <div class="login-container">
      <div class="login-left">
        <div class="main_box">
          <div class="login_title">
            <div>四川省移民后期扶持</div>
            <div>信息监测评估管理系统</div>
          </div>

          <div class="login_content"></div>
        </div>
      </div>

      <LoginBox v-if="activeIndex == 'login'" @changeActiveIndex="changeActiveIndex" />

      <RegBox v-if="activeIndex == 'reg'" @changeActiveIndex="changeActiveIndex" />
    </div>
  </div>
</template>

<script>
import LoginBox from '@/views/userLoginReg/components/LoginBox.vue'
import RegBox from '@/views/userLoginReg/components/RegBox.vue'

export default {
  components: {
    LoginBox,
    RegBox
  },
  data() {
    return {
      activeIndex: 'login'
    }
  },
  created() {},
  mounted() {},
  methods: {
    changeActiveIndex(value) {
      this.activeIndex = value
    }
  }
}
</script>

<style>
.login-container .el-input__inner {
  background: unset;
  color: #00a0e9;
}

.login-container input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset !important;
}

.login-container input:-internal-autofill-previewed,
.login-container input:-internal-autofill-selected {
  -webkit-text-fill-color: #333 !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
</style>
<style lang="less" scoped>
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  padding: 50px 0 ;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #fbfdff;
}

button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 50%;
  border: none;
  border-radius: 14px;
  opacity: 1;
  background: #3f8cff;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.2637);
}

.login-container {
  width: 14rem;
  height: 8.25rem;
  // text-align: left;
  border-radius: 11px;
  // box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  min-width: 900px;
  min-height: 500px;

  .login-left {
    flex: 0 1 47%;
    background-image: url(~@/assets/login/bgvlue.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 24px 0 0 24x;
    display: flex;
    justify-content: center;
    border-radius: 24px 0 0 24px;

    & > .main_box {
      width: 80%;
      margin-top: 0.625rem;

      & > .login_title {
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;

        & > div:first-child {
          font-size: 0.45rem;
        }
        & > div:last-child {
          font-size: 0.275rem;
        }
      }
      & > .login_content {
        width: 100%;
        height: 3.5rem;
        background-image: url(~@/assets/login/bgcenter.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 1.25rem;
      }
    }
  }
}

.forge-password {
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #515151;
  cursor: pointer;
  font-size: 0.1875rem;
  text-decoration: underline;
  float: right;
}

.el-input__inner {
  background-color: transparent !important;
}

/deep/ .ant-input-affix-wrapper .ant-input {
  border-radius: 11px;
}

/deep/ .ant-btn-primary {
  border-radius: 23px;
}

.user-layout-login {
  height: 4rem;
}

.qr-disabled {
  filter: grayscale(100%) im !important;
}
</style>
